.div{
    display: flex;
    width: 1140px;
    margin: 3rem auto;
}

.carousel{
    width: 65%;
    box-shadow: 2px 2px 7px rgba(0,0,0,0.4);
    height: fit-content;
}

.info{
    margin-left: 3rem;
    text-align: left;
    width: 35%;
}

.info h2{
    color: #0A499A;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    border-bottom: solid 1.5px #0A499A;
    width: 100%;
    padding-bottom: 0.75rem;
}

.info p{
    font-size: 18px;
    font-weight: 300;
    color: #666666;
    margin: 0.75rem 0;
}

.price{
    color: #F43536 !important;
    border: solid 2px #F43536;
    font-size: 24px !important;
    font-weight: 400 !important;
    letter-spacing: 0.1rem;
    border-radius: 2px;
    text-align: center;
    padding: 0.5rem;
    margin-top: 3rem !important;
    margin-bottom: 0.5rem !important;
}

.contado{
    font-size: 18px !important;
}

.whatsapp{
    background-color: #00D95F;
    border-radius: 2px;
    text-align: center;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    margin-top: 0;
}

.whatsapp a{
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 2.25rem;
}

.whatsapp img{
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    margin-right: 0.75rem;
}

.back{
    text-align: center;
    margin-top: 1rem !important;
}

.back a{
    color: #666666;
    font-size: 18px;
}

.back a:hover{
    color: #1A1A1A;
}

.ul{
    margin: 0 1rem;
}

.li{
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.1rem;
    font-family: Open Sans, sans-serif;
    font-weight: 300;
}

@media (max-width: 1025px){
    .div{
        width: 90%;
        display: block;
    }

    .carousel{
        width: 100%;
    }
    
    .info{
        width: 100%;
        margin: 2rem auto;
    }

    .info h2{
        width: 48%;
    }

    .infoContent{
        display: flex;
    }

    .infoText, .infoButton{
        width: 50%;
    }

    .back{
        text-align: right;
    }
}

@media (max-width: 721px){
    
    .info h2{
        width: 100%;
    }

    .infoContent{
        display: block;
    }

    .infoText, .infoButton{
        width: 100%;
    }

    .back{
        text-align: center;
    }

}