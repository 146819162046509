.icons.visible {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s ease-in-out;
}

.icons {
    position: fixed;
    z-index: 9999;
    bottom: 0.5rem;
    right: 2rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.whatsapp{
    width: 4rem;
    margin-top: 0.5rem;
}

.whatsapp img{
    width: 4rem;
}

.scrollToTop {
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    opacity: 0.5;
    margin-right: 0.25rem;
}

.scrollToTop:hover {
    opacity: 1;
}

.scrollToTop img {
    width: 3rem;
}

@media (max-width: 1025px){

    .icons {
        right: 1rem;
    }

    .whatsapp{
        width: 4rem;
    }
    
    .whatsapp img{
        width: 4rem;
    }

    .scrollToTop img {
        width: 3rem;
    }
}

@media (max-width: 721px){

    .icons {
        right: 0.5rem;
    }

    .scrollToTop {
        display: none;
    }

}