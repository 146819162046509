.div{
    height: 100%;
    width: 100%;
    position: relative;
}

.div .bgImg{
    position: absolute;
    z-index: 1;
}

.bgImg img{
    width: 100%;
}

.content{
    width: 1140px;
    height: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.image{
    width: 50%;
    margin-left: 4rem;
}

.image img{
    width: 20rem;
}

.info{
    width: 50%;
    text-align: left;
    padding-left: 1rem;
    box-sizing: border-box;
}

.info{   
    padding-left: 0;
}

.info h2{
    color: #FCDC5B;
    font-size: 36px;
    border-bottom: 2px solid #FCDC5B;
    width: 80%;
    padding-bottom: 0.75rem;
}

.info p{
    color: white;
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 1025px){

    .content{
        width: 90%;
        height: 420px;
    }

    .image{
        margin-left: 1rem;
    }
    
    .image img{
        width: 15rem;
    }
    
    .info h2{
        font-size: 28px;
    }
    
    .info p{
        font-size: 16px;
    }
}

@media (max-width: 721px){

    .div{
        height: 650px;
    }

    .div .bgImg{
        height: 650px;
    }

    .content{
        width: 90%;
        height: 650px;
        flex-direction: column;
    }

    .bgImg img{
        height: 100%;
        object-fit: cover;
    }

    .image{
        width: 100%;
        margin-left: 0;
    }

    .image img{
        width: 8rem;
    }

    .info{
        width: 100%;
        text-align: center;
        margin-top: 1.5rem;
    }

    .info h2{
        width: 80%;
        margin: 0 auto 1rem;
        font-size: 28px;
    }
    
    .info p{
        font-size: 14px;
    }
}