.div{
    text-align: left;
}

.div p img{
    width: 1.5rem;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
}

.div p a{
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    display: inline-block;
}

.div p a:hover{
    text-decoration: underline;
}

.div p{
    height: 35px;
    margin: 1.5rem 0;
}

.map{
    margin-bottom: 2rem;
}

@media (max-width: 721px){
    
    .div p a{
        font-size: 14px;
    }

}
