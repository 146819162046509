.div{
    margin-bottom: 2rem;
    padding-bottom: .75rem;
    width: 100%;
    text-align: right;
    border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
}

.div .filter select{
    margin-left: 0.5rem;
    font-size: 12px;
    font-weight: 300;
    padding: 0.1rem;
}

.div .filter p{
    display: inline-block;
    font-size: 12px;
    color: #1A1A1A;
}

.div .filter{
    width: fit-content;
    display: inline;
    margin-left: 1.5rem;
}

@media (max-width: 1025px){

    .div{
        display: flex;
        flex-wrap: wrap;
    }

    .div .filter{
        width: calc(50% - 2rem);
    }
}

@media (max-width: 721px){
    .div .filter{
        width: 100%;
    }
}