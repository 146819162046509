.div{
    width: 1140px;
    margin: 5rem auto 2rem;
}

.div h2{
    color: #F43536;
    font-size: 20px;
    letter-spacing: 0.1rem;
    font-weight: 400;
    text-align: left;
    border-bottom: 1.5px solid #F43536;
    padding-bottom: 0.75rem;
    margin: 2rem auto;
  }

.modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: auto; /* Agrega una barra de desplazamiento si el contenido excede la altura máxima */
}

.modalContent img,
.modalContent video {
    max-height: 80vh; /* Máxima altura permitida (60% del viewport height) */
    width: auto;
    max-width: 100%;
    object-fit: contain; /* Ajusta la imagen o video dentro del contenedor sin deformar */
    display: block;
    margin: 1rem auto;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.modalContent p{
    font-size: 22px;
    font-weight: 600;
    margin: 3rem auto 1rem;
    color: #0A499A;
    padding-bottom: 1rem;
}

.modalContent button{
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 24px;
    border: none;
    color: black;
    font-weight: 300;
    border-radius: 4px;
}

.portfolioImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.portfolioVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.portfolioGroup{
    display: flex;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.portfolio{
    width: calc(25% - 0.25rem);
    height: 12rem;
    position: relative;
}
  
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fondo negro transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Inicialmente invisible */
    transition: opacity 0.3s; /* Transición de opacidad para suavizar el efecto */
}
  
.portfolio:hover .overlay {
    opacity: 1; /* Hacer visible al posar el mouse sobre el div .portfolio */
    cursor: pointer;
}
  
.overlay p {
    color: white; /* Color del texto */
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 1rem;
    box-sizing: border-box;
}

@media (max-width: 1025px){
    .div{
        width: 90%;
    }
}

@media (max-width: 721px){

    
    .portfolioGroup{
        display: flex;
        grid-gap: 0.5rem;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
    }
    
    .portfolio{
        width: calc(50% - 0.25rem);
        height: 12rem;
        position: relative;
    }

    .modalContent button{
        position: relative;
        margin: 2rem auto 0;
        top: 0rem;
        right: 0rem;
    }
}