.div{
    height: 75px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
}

.content{
    width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content a h1{
    height: 50px;
    margin: 0;
}

.content a h1 img{
    height: 50px;

}

.nav{
    margin-left: auto;
    margin-right: 0;
    line-height: 4.5rem;
}

@media (max-width: 1025px){
    .content{
        width: 90%;
    }
}

@media (max-width: 721px){
    
    .content{
        width: 90%;
    }

    .mobileMenu{
        line-height: 75px;
        height: 1.5rem;
        width: 2rem;
        margin-left: auto;
        margin-right: 0;
        padding-top: 1.7rem;
        padding-bottom: 1.30rem;
    }

    .bar{
        width: 100%;
        height: 0.1rem;
        border-radius: 1px;
        margin-bottom: 0.6rem;
        background-color: #F43536;
    }

    .nav{
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }

}