.div{
    background-color: #0A499A;
    padding: 2rem;
    text-align: left;
    border-radius: 3px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
}

.div h3{
    color: white;
    font-weight: 400;
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: solid white 2px;
    letter-spacing: 0.25rem;
}

.label{
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    letter-spacing: 0.1rem;
}

.input{
    margin-top: .5rem;
    margin-bottom: 2rem;
}

.input input{
    width: 100%;
    height: 2rem;
    padding: .50rem;
    padding-right: 0;
    box-sizing: border-box;
}

.input textarea{
    width: 100%;
    height: 6rem;
    resize: vertical;
    padding: .50rem;
    padding-right: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.error{
    color: #EE675C;
    margin-top: -20px;
    font-size: 14px;
}

form button{
    color: white;
    border: solid 2px white;
    background-color: #0A499A;
    font-size: 16px;
    letter-spacing: 0.1rem;
    font-weight: 400;
    border-radius: 5px;
    padding: 0.75rem 2.5rem;
}

form button:hover{
    color: #0A499A;
    background-color: white;
}

form button:disabled{
    cursor: not-allowed;
    background-color: #0A499A;
    color: white;
    opacity: 0.5;
}

.formButton{
    text-align: right;
}

@media (max-width: 721px){
    .div h3{
        font-size: 18px;
    }

    form button{
        font-size: 14px;
        letter-spacing: 0.1rem;
        font-weight: 400;
        border-radius: 5px;
        padding: 0.75rem 2.5rem;
    }

}