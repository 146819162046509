.div .link{
    text-decoration: none;
}

.image {
    height: 210px;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.size p{
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.25rem 0.5rem;
}

.size{
    position: relative;
    top: -2.75rem;
    margin-left: auto;
    margin-right: 0;
    width: fit-content;
}

.price{
    text-align: left;
    color: #F43536;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-top: 0;
}

.title{
    text-align: left;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.25rem;
}


