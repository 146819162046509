.banner{
    width: 100%;
    height: 500px;
    margin-bottom: 5rem;
}

.banner1, .banner2, .banner3{
    height: 500px;
    position: relative;
}

.banner1bg, .banner2bg{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.banner1bg img, .banner2bg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.banner1render{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    height: 100%;
}

.banner1render img{
    height: 100%;
    object-fit: cover;
}

.banner1logo{
    width: 7rem;
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 3;
}

.banner1text{
    position: relative;
    width: 1140px;
    height: 100%;
    margin: 0 auto;
    z-index: 4;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.banner1text h3{
    margin: 0;
    color: white;
    font-size: 50px;
    font-weight: 500;
}

.banner1 .price{
    margin: 0;
    color: #FCDC5B;
    font-size: 40px;
    font-weight: 500;
    border-bottom: white solid 2px;
    width: 35%;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
}

.banner1 .text{
    margin: 0;
    margin-bottom: 0.5rem;
    color: white;
    font-size: 24px;
    font-weight: 500;
}

.banner1 .textPrice{
    color: #FCDC5B;
    letter-spacing: 0.1rem;
    font-size: 40px;
    font-weight: 500;
}

.banner1 .number{
    margin: 0;
    margin-top: 0.5rem;
    height: 32px;
    line-height: 32px;
}

.banner1 .number a{
    display: inline-block;
    margin-left: 0.75rem;
}

.banner1 .number img{
    display: inline-block;
    width: 2rem;
}



.banner1 p a{
    text-decoration: none;
    color: #FCDC5B;
    font-style: italic;
    font-size: 20px;
    font-weight: 400;
}

.banner1 p a:hover{
    text-decoration: underline;
}

.banner2 .content{
    position: relative;
    z-index: 3;
    width: 1140px;
    margin: 0 auto;
    height: 100%;
}

.banner2 .vector{
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    width: 100%;
}

.banner2 .contentImg{
    height: 475px;
    position: absolute;
    left: -5rem;
    bottom: 0;
    
}

.banner2 .contentImg img{
    height: 100%;
    object-fit: cover;
}

.banner2 .contentText{
    position: absolute;
    bottom: 2rem;
    right: 0;
    width: 50%;
}

.banner2 .textBlue{
    color: #0A499A;
    text-align: right;
    font-size: 24px;
    border-bottom: #0A499A 2px solid;
    padding-bottom: 1rem;
}

.banner2 .textBlue a{
    font-style: italic;
    color: #0A499A;
    text-decoration: none;
}

.banner2 .textBottom{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5rem;
}

.banner2 .textBottom p{
    color: #666666;
    font-size: 21px;
}

.banner2 .textRed{
    letter-spacing: 0.15rem;
    color: #F43536;
    font-size: 33px;
}

.banner2 .contentLogo{
    width: 5rem;
    margin-left: 1.5rem;
}

.banner3 a{
    text-decoration: none;
}

.banner3bg{
    position: absolute;
    z-index: 1;
    background-color: #0A499A;
    width: 100%;
    height: 20%;
    bottom: 0;
    left: 0;
}

.banner3 .content{
    position: relative;
    z-index: 2;
    width: 1140px;
    margin: 0 auto;
    height: calc(100% - 7.5rem);
    box-sizing: border-box;
    padding: 2rem 0 1rem;
}

.banner3 h3{
    color: #F43536;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.3rem;
    border-bottom: #F43536 1.5px solid;
    padding-bottom: 0.75rem;
    margin: 0 0 2rem;
}

.banner3 .images{
    display: flex;
    grid-gap: 0.5rem;
    min-height: 100%;
}

.banner3 .imageContainer{
    background-color: #0A499A;
    padding: 0.3rem;
    width: 33%;
    position: relative;
}

.banner3 .imageContainer img{
    height: 100%;
    object-fit: cover;
    z-index: 1;
    width: 100%;
}

.imageOverlay {
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: calc(100% - 0.6rem);
    height: calc(100% - 0.6rem);
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.8) 100%);
    z-index: 2;
  }

.banner3 .imageContainer p {
    color: white;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: -2.75rem;
    position: relative;
    z-index: 3;
    letter-spacing: 0.1rem;
}

@media (max-width: 1025px){

    .banner{
        height: 400px;
    }
    
    .banner1, .banner2, .banner3{
        height: 400px;
    }

    .banner1text{
        width: 90%;
    }

    .banner1text h3{
        font-size: 40px;
    }
    
    .banner1 .price{
        font-size: 40px;
    }
    
    .banner1 .text{
        font-size: 20px;
    }
    
    .banner1 p a{
        font-size: 18px;
    }

    .banner2 .content{
        width: 90%;
    }

    .banner2 .contentImg{
        height: 320px;
        left: 0;
    }

    .banner2 .contentText{
        width: 50%;
    }

    .banner2 .textBlue{
        font-size: 20px;
    }

    .banner2 .textBottom{
        margin-top: 2rem;
    }
    
    .banner2 .textBottom p{
        font-size: 14px;
    }
    
    .banner2 .textRed{
        font-size: 20px;
    }

    .banner3 .content{
        width: 90%;
    }

}

@media (max-width: 721px){
    .banner{
        height: 600px;
    }
    
    .banner1, .banner2, .banner3{
        height: 600px;
    }

    .banner1 h3{
        font-size: 30px;
    }

    .banner1 .price{
        font-size: 30px;
        padding-bottom: 0.75rem;
        margin-bottom: 1rem;
        width: 100%;
    }

    .banner1text{
        width: 100%;
        position: static;
        background-color: #0A499A;
        height: 60%;
        padding: 1rem 2rem;
    }

    .banner1 .text{
        font-size: 18px;
    }

    .banner1 .number{
        margin-top: 0;
        height: 1.5rem;
        line-height: 1.5rem;
    }

    .banner1 .number img{
        width: 1.5rem;
    }

    .banner1render{
        position: static;
        width: 100%;
        height: 40%;
    }

    .banner1render img{
        width: auto;
        height: auto;
        object-fit: cover;
    }

    .banner2 .content{
        width: 100%;
    }

    .banner2 .vector{
        width: 160%;
    }
    
    .banner2 .contentImg{
        height: 340px;
        position: relative;
        
    }
    
    .banner2 .contentText{
        position: static;
        width: 90%;
        margin: 0 auto;
    }
    
    .banner2 .textBlue{
        text-align: center;
        font-size: 20px;
        padding: 3rem 0 1rem;
        margin: 0 auto;
    }
    
    .banner2 .textBottom{
        justify-content: center;
        flex-direction: column;
        margin-top: 0;
    }
    
    .banner2 .textBottom p{
        font-size: 18px;
        margin: 1rem auto;
    }
    
    .banner2 .textRed{
        font-size: 28px;
    }
    
    .banner2 p.contentLogo{
        width: 100%;
        margin-left: 0;
        margin: 0 auto 1rem;
        display: flex;
        grid-gap: 1rem;
        justify-content: center;
    }

    .banner2 .contentLogo img{
        width: 5rem;
    }

    .banner3 .images{
        flex-direction: column;
    }

    .banner3 h3{
        font-size: 22px;
        margin: 0 0 1.5rem;
    }

    .banner3 .imageContainer{
        padding: 0.3rem;
        width: 100%;
        height: 9rem;
        margin: 0 auto;
    }
}