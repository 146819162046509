.div {
    width: 1140px;
    margin: 3rem auto;
}

.heading {
    width: calc(50% - 25px);
    text-align: left;
    margin-bottom: 3rem;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.25rem;
    border-bottom: 2px solid #F43536;
    color: #F43536;
    padding-bottom: 1rem;
}

.content {
   display: flex;
}

.article{
    width: calc(50% - 30px);
}

.form{
    margin-right: 0;
    margin-left: auto;
}

@media (max-width: 1025px){
    .div{
        width: 90%;
    }
}

@media (max-width: 721px){
    .div{
        width: 90%;
    }

    .heading {
        font-size: 20px;
        width: 100%;
    }

    .content {
        display: block;
     }

    .article{
        width: 100%;
    }

    .form{
        margin-bottom: 2rem;
    }
}